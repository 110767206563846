import React, {Fragment} from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Navbar className="navbar-fixed-top white-page"/>
      <header className="header legal">
        <div className="container tokenomics">
          <div className="row headline">
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <div className="row">
                <div className="title">Dora.ai
                </div>
                <h1 className="page-type">Tokenomics</h1>
                <div className="skayline"></div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <section className="post-body legal">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <div className="version">v1.0.0
              </div>
            </div>
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <article className="post">
                <p>We provide a detailed overview of ou utility token $DORA, and its distribution.
                  <br/>&nbsp;</p>
                <h3>Total Supply and Token Allocation<br/>&nbsp;</h3>
                <p>
                  <strong>Total Supply:</strong> The total number of $DORA tokens is 523,415,926, with a circulating
                  supply of 523,415,926 tokens.
                </p>
                <p>
                  <strong>Defi Swap Fee:</strong> For swapping on DEXs we added a 0.25% tax. This tax is used to
                  replenish the staking pool making sure there are always enough tokens for rewards. We want to
                  encourage trading, so we wanted to
                  support it by having a very low tax.
                  <br/>
                  The tax is not applicable when buying and selling tokens on Centralised Exchanges or when transferring
                  tokens between wallets.
                </p>
                <p>
                  <strong>Inflation:</strong> No inflation, minting or mining. The total supply of $Dora tokens is
                  fixed.
                </p>

                <h3>
                  <strong>Token Allocation:</strong>
                </h3>
                <br/>


                <StaticImage src={"../assets/images/token-allocation-new.png"}></StaticImage>

                <br/>

                <ul>
                  <li><strong>Token Sale</strong> - 20% Will be sold in the token sale/IDO</li>
                  <li><strong>Team Members</strong> - 20% Will be gradually released to the team members and founders.
                  </li>
                  <li><strong>Foundation</strong> - 15% This is the company's vault. These funds will be used to invest
                    in the infrastructure of our projects, but also when opportunity presents itself we will use these
                    funds to make possible acquisitions of startups.
                  </li>
                  <li><strong>Liquidity Pools</strong> - 10% We will use these funds to offer liquidity to DEXs.
                  </li>
                  <li><strong>Exchange Listing</strong> - 10% We will use these funds to offer liquidity to exchanges.
                  </li>
                  <li><strong>Community Rewards</strong> - 10% Funds used for rewarding the people in our community.
                  </li>
                  <li><strong>Staking Rewards</strong> - 5% These funds will be used to reward the holders of staked
                    tokens.
                  </li>
                  <li><strong>Marketing</strong> - 5% These funds will be used to promote the project.</li>
                  <li><strong>Advisors</strong> - 5% These funds will be gradually released to our advisors.</li>
                </ul>

                <p>We are strong believers in using these funds wisely. As we have no minting, mining or inflation in
                  the $Dora token we think of them as precious assets that we value.</p>

                <h3>Vesting Periods</h3>
                <p>When launching a new token, it's crucial to have a
                  well-thought-out vesting schedule to ensure the fair distribution of tokens and incentivize long-term
                  commitment from stakeholders. Here's the release schedule for the Dora AI token:</p>


                <table className="table table-striped">
                  <thead>
                  <tr>
                    <th>
                      <span className="title">Category</span>
                    </th>
                    <th>
                      <span className="title">Allocation</span>
                    </th>
                    <th>
                      <span className="title">Distribution Schedule</span>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                    <td>Token Sale</td>
                    <td>20%</td>
                    <td>100% Released on Token Generation Event</td>
                  </tr>

                  <tr>
                    <td>Team Members</td>
                    <td>20%</td>
                    <td>20% Released on TGE; 80% Linear Vesting over 12 Months</td>
                  </tr>

                  <tr>
                    <td>Foundation</td>
                    <td>15%</td>
                    <td>10% Released on TGE; 90% Linear Vesting over 12 Months</td>
                  </tr>

                  <tr>
                    <td>Liquidity Pools</td>
                    <td>10%</td>
                    <td>100% Released on TGE</td>
                  </tr>

                  <tr>
                    <td>Exchange Listing</td>
                    <td>10%</td>
                    <td>100% Released on TGE</td>
                  </tr>

                  <tr>
                    <td>Community Rewards</td>
                    <td>10%</td>
                    <td>10% Released on TGE; 90% Linear Vesting over 24 Months</td>
                  </tr>

                  <tr>
                    <td>Staking Rewards</td>
                    <td>5%</td>
                    <td>10% Released on TGE; 90% Linear Vesting over 12 Months</td>
                  </tr>


                  <tr>
                    <td>Marketing</td>
                    <td>5%</td>
                    <td>100% Released on TGE</td>
                  </tr>

                  <tr>
                    <td>Advisors</td>
                    <td>5%</td>
                    <td>20% Released on TGE; 80% Linear Vesting over 12 Months</td>
                  </tr>

                  </tbody>
                </table>

                <p>This vesting schedule ensures that tokens are distributed responsibly and in a manner that aligns
                  with the long-term goals of the Dora AI project. By staggering the release of tokens and
                  implementing vesting periods, we aim to foster a sustainable and thriving ecosystem around the Dora
                  AI token.</p><p>Stay tuned for more updates and developments as we continue to build and grow the
                Dora AI platform.</p>

                <h3>Projects Economy</h3>
                <p>Our project economy is designed to create a sustainable ecosystem around the $DORA token. Here’s a
                  detailed look at how $DORA tokens will be utilized:</p>
                <ul>
                  <li><strong>Infrastructure Costs:</strong> Covering server expenses, cloud services, and data storage
                    to ensure the platform remains reliable and scalable.
                  </li>
                  <li><strong>Project Maintenance and Support:</strong> Funding ongoing maintenance, updates, customer
                    support, and user engagement activities to ensure a seamless experience.
                  </li>
                  <li><strong>Development of New Products:</strong> Investing in R&D to create innovative AI products
                    and services, keeping us at the forefront of AI technology.
                  </li>
                  <li><strong>Ecosystem Incentives:</strong> Implementing programs like staking rewards, community
                    contests, and referral bonuses to foster a vibrant community and drive platform growth.
                  </li>
                  <li><strong>Strategic Partnerships and Acquisitions:</strong> Using tokens to form strategic
                    partnerships and acquire startups, enhancing our offerings and integrating new technologies.
                  </li>
                </ul>
                <p>By thoughtfully managing the allocation and use of $DORA tokens, we aim to build a sustainable,
                  innovative, and user-centric ecosystem. Our commitment to transparency and responsible token
                  management will ensure the continuous growth and success of the Dora AI platform.</p>


                <h3>You can buy $Dora tokens here:<br/></h3>
                <ul>
                  <li>public launchpad - <strong>update as soon as we have a date</strong></li>
                  <li>DEXs - <strong>update as soon as we're ready to create the liquidity pool</strong></li>
                  <li>CEXs - <strong>update as soon as we have news</strong></li>
                </ul>

                Token contract: <strong>update soon</strong>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </Layout>
  )
}

export default PrivacyPolicy;
